// 数据中心
import request from '@/request/request';

//顶部数据
export const IndexApi = (params) => request.post('/api/DataScreen/index', params);

//项目情况
export const projectSituationApi = (params) => request.post('/api/DataScreen/projectSituation', params);

//成交量
export const dealDataApi = (params) => request.post('/api/DataScreen/deal_data', params);

//供应商申请入驻量
export const suppliersAddApi = (params) => request.post('/api/DataScreen/suppliers_add', params);

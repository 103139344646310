<template>
  <!--  -->
  <Layout :side='false'>
    <div class="main-data">
      <!-- 头部 -->
      <div class="md-data-1">
        <div class="msg flex-center">
          <img class="logo" src="@/assets/images/data-logo.png">
          <div class="name flex-middle">
            <div class="name-text s30 color-f font-bold">
              <div class="t1">易筑平台华南运营中心交易大数据</div>
              <div class="t2">易筑平台华南运营中心交易大数据</div>
            </div>
            <!-- <img src="@/assets/images/data-name.png" alt=""> -->
          </div>
        </div>
        <!-- 当前时间 -->
        <div class="time text-center s14">{{dateNow | dateFormat('yyyy-MM-dd HH:mm')}}</div>
      </div>
      <!--  -->
      <div class="md-data-2 flex">
        <div class="col-1">
          <div class="panel">
            <div class="panel-hd s18 font-medium">交易数据</div>
            <div class="panel-bd">
              <div class="list s14 flex-wrap text-center">
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.project_amount }}</div>
                    <div class="t2">项目总金额</div>
                  </div>
                </div>
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.project_num }}</div>
                    <div class="t2">管理工程项目</div>
                  </div>
                </div>
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.bidding_num }}</div>
                    <div class="t2">发布投标项</div>
                  </div>
                </div>
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.visitor_num }}</div>
                    <div class="t2">访客数UV</div>
                  </div>
                </div>
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.goods_sku_num }}</div>
                    <div class="t2">SKU总数</div>
                  </div>
                </div>
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.user_num }}</div>
                    <div class="t2">注册会员数</div>
                  </div>
                </div>
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.order_num }}</div>
                    <div class="t2">成交订单数</div>
                  </div>
                </div>
                <div class="li flex-center">
                  <div class="li-cnt">
                    <div class="t1 s24 font-medium">{{ project_situation.suppliers_num }}</div>
                    <div class="t2">入驻供应商数</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 text-center">
          <div class="t1 s24 font-bold">累计成交金额(元)</div>
          <div class="t2 s70 font-tit"  style="display: flex;justify-content: center;">
            <div
                v-for="(item, itemIndex) in dealMoneyCount"
                :key="itemIndex"
                style="display: flex"
              >
                <div
                  v-for="(i, index) in item"
                  :key="index"
                  style="display: flex"
                >
                  <div class="totalAmountBox-items-item-i-value">
                    {{ i }}
                  </div>
                  
                </div>
                <div class="symbol" v-if="itemIndex != dealMoneyCount.length - 1">,</div>
              </div>
          </div>
        </div>
        <div class="col-3">
          <div class="panel">
            <div class="panel-hd s18 font-medium flex-between flex-middle">
              <span>供应商申请入驻量</span>
              <div class="des flex-middle">
                <span class="s14">申请入驻量</span>
              </div>
            </div>
            <div class="panel-bd">
              <div id="main"></div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="md-data-3 flex-between">
        <div class="col-1">
          <div class="panel">
            <div class="panel-hd s18 font-medium flex-between flex-middle">
              <span>项目情况</span>
              <span class="s14 t2">{{ today }}</span>
            </div>
            <div class="panel-bd mainBox">
              <vue-seamless-scroll
                :data="projectSituation"
                :class-option="optionSingleHeightTime"
                class="warp"
              >
                <div v-for="(item, index) in projectSituation" :key="index" class="projectItem">
                    <div class="imgList">
                      <div
                        class="imgItem"
                        v-for="(imgItem, index) in item.original_img"
                        :key="index"
                      >
                      <el-image :src="imgItem" class="image"></el-image>
                      </div>
                    </div>
                  <div class="content">
                    <div class="content-projectName">
                      项目名称：{{ item.title }}
                    </div>
                    <div class="content-projectScale">
                      项目规模：{{ item.construction_scope }}
                    </div>
                    <div class="content-projectAddress">
                      项目地址：{{ item.project_location }}
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="panel">
            <div class="panel-hd flex-middle flex-between s18 font-bold">
              <span>最新交易情况</span>
              <!-- <swiper :options='options2' class="msg-wrap">
                <swiper-slide v-for="(item,index) in msg_list" :key="index">
                  <div class="msg s14 flex-middle">
                    <img src="@/assets/images/man.png" alt="">
                    <span>{{ item }}</span>
                  </div>
                </swiper-slide>
              </swiper> -->
              <div class="msg">
                <vue-seamless-scroll
                  :data="msg_list"
                  :class-option="msgoptionSingleHeightTime"
                  class="warp"
                >
                  <div v-for="(item, index) in msg_list" :key="index" class="transItem">
                    <img src="@/assets/images/man.png" alt="">
                    <span class="tip"
                      >{{ item }}</span
                    >
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
            <div class="panel-bd">
              <!-- <table class="table s16">
                <thead>
                  <tr>
                    <td>商品名称</td>
                    <td>采购人</td>
                    <td>下单金额</td>
                    <td>时间</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in engineer_data" :key="index">
                    <td>{{ item.goods_name }}</td>
                    <td>{{ item.nickname }}</td>
                    <td>{{ item.total_amount }}元</td>
                    <td>{{ item.add_time }}</td>
                  </tr>
                </tbody>
              </table> -->
              <div id="scroll-board">
                <dv-scroll-board :config="config"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import dayjs from "dayjs";
import * as echarts from 'echarts';
import { IndexApi, projectSituationApi, dealDataApi, suppliersAddApi } from "@/request/api/data";
import { dealMoneyCountfun } from "@/utils/index";
export default {
  data() {
    return {
      timer: '',
      dateNow: '',
      notNextTick: true,
      options: {
        slidesPerView: 1,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        speed: 500,
        spaceBetween: 20,
        autoplay: 3000,
        loop: true
      },
      options2: {
        direction : 'vertical',
        autoplay: 3000,
        loop: true,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:false,//修改swiper的父元素时，自动初始化swiper
      },
      project_situation: {},
      dealMoneyCount: 0,
      projectSituation: [],
      today: dayjs(new Date()).format("YYYY[年]MM[月]DD[日]"),
      engineer_data: [],
      msg_list: [],
      suppliers_list: [],
      chartOption: {
        tooltip: {},
        xAxis: {
          data: [],
          type: 'category',
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: '#116c91'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            lineStyle: {
              type:'dashed',
              color: '#15a4c5'
            }
          }
        },
         grid: {
          left: '0%',
          right: '0%',
          top: '3%',
          bottom: '0%',
          containLabel: true
        },
        series: [
          {
            name: '入驻量',
            type: 'bar',
            barWidth: '30%',
            data: [],
            label: { //柱体上显示数值
              show: true,//开启显示
              position: 'top',//在上方显示
              style: { // 数值样式
                color: "#fff",
                fontSize: 10
              },
            },
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#1581b4' // 0% 处的颜色
                }, {
                    offset: 1, color: '#2eb8ca' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            }
          }
        ]
      },
      config: {
        header: ["商品名称", "采购人", "下单金额", "时间"],
        data: [],
        columnWidth: [300, 100, 80,120],
        align: ['center','center','center','center'],
        rowNum: 5,
        headerBGC: "#1E408A",
        headerHeight: 40,
        oddRowBGC: "",
        evenRowBGC: "",
      },
      timer1: null,
      
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    optionSingleHeightTime() {
      return {
        step: 1.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 21, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 500, // 单步运动停止的时间(默认值1000ms)
      };
    },
    msgoptionSingleHeightTime(){
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 26, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1200, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {

  },
  beforeDestroy(){
    clearInterval(this.timer1);
  },
  async mounted() {
    await this.getSuppliersAdd();
    this.getTime()
    this.setChart();
    this.getIndexData();
    this.getProjectSituation();
    this.getDealData();
    this.timer1 = setInterval(async () => {
      this.getProjectSituation();
    },120000)
    
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getTime() {
      this.timer = setInterval(() => {
        this.dateNow = Date.now()
      }, 1000)
    },
    setChart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'));
      // 绘制图表
      myChart.setOption(this.chartOption);
      
    },
    //顶部数据
    getIndexData(){
      IndexApi().then(res => {
        if(res.status == 200){
          this.project_situation = res.data.project_situation;
          let dealMoneyCountTemp = parseInt(res.data.deal_money_count).toString();
          this.dealMoneyCount = dealMoneyCountfun(dealMoneyCountTemp);
        }
      })
    },
    //项目情况
    getProjectSituation(){
      projectSituationApi().then(res => {
        if(res.status == 200){
          this.projectSituation = res.data;
        }
      })
    },
    async getDealData(){
      this.config.data = [];
      const res = await dealDataApi();
      if(res.status == 200){
        this.msg_list = res.data.msg_list;
        let temp = res.data.engineer_data;
        temp.forEach(item => {
          let configItem = [];
          Object.keys(item).forEach((key) => {
            configItem.push(item[key]);
          })
          this.config.data.push(configItem);
        })
        this.config={...this.config}
      }
    },
     //入驻量
    async getSuppliersAdd(){
      await suppliersAddApi().then(res => {
        if(res.status == 200){
          let resTemp = res.data;
          let xAxisData = [];
          let value = [];

          for(let item of resTemp){
            xAxisData.push(item.month + '月');
            value.push(item.supplier_count);
          };

          this.chartOption.xAxis.data = xAxisData;
          this.chartOption.series[0].data = value;

          console.log(this.chartOption.xAxis.data);
          console.log(this.chartOption.series[0].data);
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.main-data{
  background: #000 url(~@/assets/images/data-bg.jpg) no-repeat center / cover;
  min-height: 9.59rem;
}
.md-data-1{
  background: url(~@/assets/images/data-head-bg.png) no-repeat center top / cover;
  height: 1.53rem;
  padding-top: .31rem;
  .msg{
    .logo{
      width: .4rem;
      margin-right: .13rem;
    }
    .name{}
    .name-text{
      letter-spacing: 0.03rem;
      position: relative;
      .t1{
        position: relative; z-index: 2;
        background: linear-gradient(to bottom, #ebf4f8, #86b8d9);
        -webkit-background-clip: text; /* Safari/Chrome支持此特定前缀 */
        -webkit-text-fill-color: transparent; /* Safari/Chrome支持此特定前缀 */
        color: transparent; /* Firefox不支持此属性，所以我们将颜色设为透明 */
      }
      .t2{
        position: absolute; left: 0; top: 1px; z-index: 1;
        width: 100%;
      }
    }
  }
  .time{
    color: #bcd8ea;
    margin-top: .09rem;
  }
}
.md-data-2{
  padding: 0 .6rem;
  .panel-hd{
    background-image: url(~@/assets/images/data-tit-bg2.png);
  }
  .col-1{
    width: 5.8rem;
    .list{
      color: #c3dde3;
      padding-top: .23rem;
      padding-left: .24rem;
      .li{
        width: 1.27rem; height: 1.27rem;
        margin-right: .08rem; margin-bottom: .08rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        &:nth-of-type(2n+1){
          background-image: url(~@/assets/images/data-bg2.png);
        }
        &:nth-of-type(2n){
          background-image: url(~@/assets/images/data-bg3.png);
        }
        &:nth-of-type(4n){
          margin-right: 0;
        }
      }
      .t1{
        color: #7ce6ff;
      }
    }
  }
  .col-2{
    flex: 1;
    padding: .68rem .3rem 0;
    .t1{
      color: #37cff2;
    }
    .t2{
      color: #5be0ff;
      margin-top: .22rem;
      text-shadow: 0 0 .2rem rgba(124, 230, 255, 0.6);
    }
  }
  .col-3{
    width: 5.8rem;
    .panel-hd{
      .des{
        &:before{
          content: '';
          width: 0.19rem;
          height: 0.12rem;
          background-image: linear-gradient(0deg, 
            #1682b4 0%, 
            #2db6ca 100%);
          border-radius: 0.03rem;
          margin-right:  .07rem;
        }
        color: #24a3c1;
      }
    }
    .panel-bd{
      padding: .3rem;
    }
    #main{
      height: 2.5rem;
    }
  }
}
.panel{
  &-hd{
    background: url(~@/assets/images/data-tit-bg.png) no-repeat center left / auto 100%;
    height: .45rem;
    line-height: .45rem;
    color: #37cff2;
    padding-left: .35rem;
  }
  &-bd{
    height: 3.06rem;
    margin-top: .1rem;
    background-image: linear-gradient(to bottom, 
      rgba($color: #215dac, $alpha: .45),
      transparent);
    border: .02rem solid rgba($color: #0f8bc1, $alpha: .05);
  }
}
.md-data-3{
  margin-top: .3rem;
  padding: 0 .6rem;
  .col-1{
    margin-right: .3rem;
    flex: 1; overflow: hidden;
    .panel-hd{
      padding-right: .02rem;
      .t2{
        color: #15a4c5;
      }
    }
    .panel-bd{
      padding: .3rem;
    }
    .list{
      padding-bottom: .26rem;
      .imgs{
        .img{
          width: 1.57rem; height: .88rem;
          object-fit: cover;
          margin-right: .1rem;
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
      .text{
        margin-top: .2rem;
        line-height: calc(36 / 16 * 1em);
      }
      ::v-deep .swiper-pagination{
        bottom: 0;
        font-size: 0;
        &-bullet{
          width: .1rem; height: .1rem;
          opacity: .2;
          background: #fff;
          margin: 0 .06rem!important;
          &-active{
            background-color: #37cff2;
            opacity: 1;
          }
        }
      }
    }
  }
  .col-2{
    flex: 1;
    .panel-hd{
      .msg-wrap{
        height: .45rem; margin-right: 0;
      }
      .msg{
        color: #15a4c5;
        img{
          width: .13rem; margin-right: .01rem;
        }
      }
    }
    .panel-bd{
      padding: .3rem;
    }
    #scroll-board {
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;
    }
    .table{
      width: 100%;
      height: 100%;
      color: rgba($color: #fff, $alpha: .9);
      text-align: center;
      overflow: hidden;
      thead{
        height: 0.51rem;
        background-image: linear-gradient(180deg, 
          rgba($color: #2165ac, $alpha: .45) 0%, 
        rgba(33, 93, 160, 0.3) 100%);
      }
      tbody{
        tr{
          border-bottom: 1px solid rgba($color: #194681, $alpha: .7);
        }
        td{
          height: .48rem;
        }
      }
      td{}
    }
  }
  .mainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 300px;
    padding-left: 27px;
    padding-right: 37px;
    box-sizing: border-box;
    .warp {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .projectItem {
        margin-bottom: 16px;
        .imgList {
          display: flex;
          justify-content: flex-start;
          .imgItem {
            width: 1.57rem; height: .88rem;
            object-fit: cover;
            margin-right: .1rem;
            &:last-of-type{
              margin-right: 0;
            }
            .image {
              width: 100%;
              height: 100%;
            }
          }
        }
        
        .content {
          margin-top: 20px;
          color: #fff;
          font-size: 18px;
          line-height: 21px;
          &-projectName,
          &-projectScale {
            margin-bottom: 8px;
            line-height: 21px;
          }
        }
      }
    }
  }
  .msg{
    position: absolute;
    right: 0;
    height: 25px;
    line-height: 25px;
    overflow: hidden;
    .transItem{
      width: 200px;          
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
      .svg {
        margin-right: 10px;
      }
      .tip {
        font-size: 14px;
      }
    }
  }
}
</style>